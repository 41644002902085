<template>
  <div class="nav_bottom">
    <div class="nav_top_div">
      <span class="nav_top_span" @click="addFeek">
        反馈
      </span>
      -
      <span class="nav_top_span" @click="appreciate">
        赞赏
      </span>
    </div>

    <span class="nav_bottom_span">
      © www.yiningnetwork.cn 版权所有
      <a href="https://beian.miit.gov.cn" class="nav_bottom_span_a" target="_blank">皖ICP备2024054812号-1</a>
    </span>
  </div>

  <el-dialog :title="title" v-model="isAddFeek" style="text-align: center;border-radius: 10px" width="500px" append-to-body>
    <el-form ref="configRef1" label-width="80px" style="padding:10px">
      <span style="display: flex;justify-content: flex-start;padding-bottom: 5px">邮箱地址</span>
      <el-input
          v-model="contactInformation"
          type="input"
          class="aes-tabs-pane-input"
          placeholder="请输入您的邮箱地址"/>

      <span style="display: flex;justify-content: flex-start;margin-top: 20px;padding-bottom: 5px">反馈内容</span>
      <el-input
          v-model="feedbackValue"
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6}"
          class="aes-tabs-pane-input"
          resize="none"
          placeholder="请输入您的意见和反馈"/>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitAddFeek">提 交</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="isAppreciate" style="text-align: center;border-radius: 10px" width="700px" append-to-body>
    <div slot="title" class="header-title">
      <img src="img/love_20.png" />
      <span class="zbt">{{appreciateTitle}}</span>
    </div>
    <el-form ref="configRef1" label-width="80px" style="padding:10px">
      <div style="display: flex;justify-content: space-around">
        <div class="zhifubao_div">
          <el-image src="img/zhifubao.jpg" class="zhifubao_cls"></el-image>
        </div>

        <div class="weixin_div">
          <el-image src="img/weixin.jpg" class="weixin_cls"></el-image>
        </div>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>

import {submitAddFeekFun} from "@/utils/method";

export default {
  name: 'BottomWin',
  data() {
    return {
      title:'留言反馈',
      isAddFeek:false,
      feedbackValue:'',
      contactInformation:'',

      appreciateTitle:'谢谢您的支持',
      isAppreciate:false,
    };
  },
  methods:{
    addFeek(){
      this.isAddFeek = true;
    },
    submitAddFeek(){
      submitAddFeekFun(this.feedbackValue,this.contactInformation).then(res=>{
        if(res.data.code === 200){
          this.$message.success('反馈成功！');
          this.isAddFeek = false;
          return;
        }
      });
    },
    cancel() {
      this.isAddFeek = false;
      this.isAppreciate = false;
    },
    appreciate(){
      this.isAppreciate = true;
    }
  }
}
</script>

<style scoped lang="scss">
.nav_bottom{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(247, 249, 252);
  height: 50px;
  .nav_top_div{
    font-size: 13px;
    color: #535252;
    cursor: pointer;
    padding: 3px 3px;
    .nav_top_span{
      padding: 5px;
      &:hover{
        color: #7AADF9;
      }
    }
  }
  .nav_bottom_span{
    font-size: 13px;
    color: #939191;
    .nav_bottom_span_a{
      color: #535252;
      text-decoration: none;
      &:hover{
        color: #7AADF9;
      }
    }
  }
}
.zhifubao_cls{
  width: 200px;
  height: 300px;
}
.weixin_cls{
  width: 200px;
  height: 300px;
  margin-top: 50px;
}
.header-title{
  display: flex;
  align-items: center;
  justify-content: center;
  .zbt{
    padding-left: 5px;
  }
}
</style>