import store from '@/store'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import axios from "axios";
// 创建axios实例
const service = axios.create({
    baseURL: 'https://www.yiningnetwork.cn' // api的base_url
})

// request 拦截器
service.interceptors.request.use(config => {
    // 请求头配置
    config.timeout = !config.timeout ? 5000 : config.timeout
    if (config.banned) {
        // 违禁词管理
        config.baseURL = process.env.VUE_APP_BASE_API2
    }
    // 老版本post请求参数都是params，这里统一处理下
    if (config.method === 'post' && config.params) {
        config.data = JSON.stringify(config.params)
        delete config.params
    }
    if (store.getters.token) { // 如果token存在，则headers放置token
        config.headers._token = getToken()
        config.headers['X-Token'] = getToken() // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
})

// respone 拦截器
service.interceptors.response.use(
    response => {
        if (response.headers['_token']) {
            setToken(response.headers['_token'])
        }
        try {
            const res = response.data
            if (res.data) {
                if (res.data.status === 401) {
                    console.log('重新登录')
                    removeToken()
                    location.reload()
                } else if (res.data.status === 505) {
                    ElMessage.warning(res.data.title)
                } else {
                    // ElMessage.warning(res.data.title)
                }
            }
        } catch (err) {
            console.log(err)
        }
        return response.data
    },
    error => {
        // todo 自定义提示
        if (error.message === 'cancel') {
            console.log('取消请求')
            ElMessage.warning('请求太频繁')
        } else {
            console.log('请求异常信息：' + error)
            return Promise.reject(error)
        }
    })

export default service

